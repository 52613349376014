.top-to-btm {
  position: relative;
}
.icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}

button.back-to-top {
  background-color: #d6ba3e;
  background-image: url('../../assets/img/scroll.png');
  background-position: center;
  background-size: contain;
    border: 2px solid #000000;
    border-radius: 50%;
    height: 60px;
    width: 60px; 
    color: #070707; 
    cursor: pointer;
    animation: movebtn 9s ease-in-out infinite;
    transition: all .1s ease-in-out;
}


.back-to-top:hover {
  animation: none;
  color: #020202;
  border: 2px solid #ffffff;
}
@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
